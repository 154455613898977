.committeeStatus {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    > * {
        margin-right: 10px;
    }
    .activeStatus {
        color: green;
    }
    .archivedStatus {
        color: orange;
    }
}