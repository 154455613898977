.committeeOverviewContainer {
    .coArea {
        max-width: 1380px;
        padding-bottom: 32px;
    }
    .coTitle {
        margin: 0;
    }
    .coAreaGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 0;
        @media (max-width: 1024px) {
            display: block;
        }
    }
    .coAreaGridItem {
        background-color: transparent;
        padding-right: 20px;
        padding-bottom: 32px;
    }
    .coAreaFlex {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        > * {
            margin-right: 16px;
        }
    }
    .coAreaFlexWrap {
        flex-wrap: wrap;
    }
    .coHeaderLink {
        background-color: transparent;
    }
    .coAreaTitle {
        padding-bottom: 4px;
        font-weight: 700;
    }

    .coDescription {
        box-sizing: border-box;
        max-width: 1380px;
        padding-right: 20px;
    }

    .coatExtraSpace {
        padding-bottom: 8px;
    }

    .coatCursorPointer {
        cursor:pointer;
    }
}