.committeeListContainer {
	max-width: 1380px;
}

.clPanelHeader {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
	h3 {
		text-transform: uppercase;
	}
	.closeIcon {
		cursor: pointer;
	}
}

.clList {
	padding-top: 24px;
	.clInfoIcon {
		cursor: pointer;
	}
	.clTitleCell {
		width: 100%;
		span {
			max-width: calc(100% - 25px);
		}
	}
	:global(.ui-box) {
		//width: 100%;
	}
	.clListHeader,
	.clListItem {
		align-items: flex-start;
		display: flex;
		justify-content: flex-start;
	}
	.clListUnSelectedIcon,
	.clListSelectedIcon,
	.clCircle {
		display: none;
	}	
	.clListItem:hover,
	.clListIsSelected {
		.clListUnSelectedIcon,
		.clCircle {
			display: inline-block;
		}
	}
	.clCircleContainer {
		height: 100%;
		width: 40px;
	}
	.clCircle {
		font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
		-webkit-font-smoothing: antialiased;
		font-size: 14px;
		font-weight: 400;
		line-height: 1;
		width: 18px;
		height: 18px;
		vertical-align: top;
		position: relative;
		user-select: none;
		&::before {
			content: "";
    		position: absolute;
    		inset: 1px;
			border-radius: 50%;
			opacity: 1;
			background: rgb(255, 255, 255);
		}
		.clIcon {
			font-size: 16px;
			position: absolute;
			left: 0.5px;
			top: 1px;
			width: 18px;
			height: 18px;
			text-align: center;
			vertical-align: middle;
			color: rgb(138, 138, 138);
		}
		.clListSelectedIcon {
			top: 0px;
		}
	}
	.clListIsSelected {
		.clCircle {
			display: inline-block;
			/*&::before {
				background: rgb(98, 100, 167);
			}
			.clIcon {
				color: rgb(255, 255, 255);
			}
			.clListUnSelectedIcon {
				font-size: 18px;
			}
			.clListSelectedIcon {
				font-weight: 900;
				top: 0.5px;
			}*/
		}
	}
	.clListHeader {
		font-weight: 600;
		button > span {
			margin-left: -30px;
		}
		.clListDivision button > span {
			margin-left: -23px;
		}
		.clListOrganisationalUnit button > span {
			margin-left: -10px;
		}
		.clListResponsibleZvei button > span {
			margin-left: -10px;
		}
		> div {
			overflow: hidden;
		}
	}
	.clListHeader,
	.clListItem {
		border-bottom: 1px solid rgb(224, 224, 224);
		border-bottom-width: 0.0625rem;
	}
	.clListItem {
		border-bottom: 1px solid rgb(240, 240, 240);
	}
	&:global(.contrast) {
		.clListHeader,
		.clListItem {
			border-bottom: 1px solid rgb(235, 235, 235);
			border-bottom-width: 1px;
		}
		.clListItem:hover {
			background-color: rgb(255, 255, 0);
			color: rgb(0,0,0);
			.clListUnSelectedIcon,
			.clListSelectedIcon {
				color: white;
			}
		}
		.clListIsSelected {
			background-color: rgb(0, 235, 255);
			color: rgb(0,0,0);
		}
	}
	.clListSelected,
	.clListInfo,
	.clListStatus,
	.clListDivision,
	.clListOrganisationalUnit,
	.clListName,
	.clListResponsibleZvei {
		padding: 8px 16px;
	}
	.clListOrganisationalUnit,
	.clListDivision,
	.clListName {
		overflow: auto;
		word-wrap: break-word;
		overflow-wrap: break-word;
	}
	.clListSelected {
		cursor: pointer;
		width: 25px;
		/*&:hover {
			.clListSelectedIcon {
				display: inline-block;
			}
		}*/
	}

	.clListIsSelected {
		.clListSelectedIcon,
		.clListUnSelectedIcon {
			display: inline-block;
		}
	}
	&:global(.dark) {
		.clListHeader,
		.clListItem {
			border-bottom: 1px solid rgb(31, 31, 31);
			border-bottom-width: 0.0625rem;
		}
		.clListItem {
			border-bottom: 1px solid rgb(26, 26, 26);
		}
		.clCircle {
			&::before {
				background: rgb(41, 41, 41);
			}
			.clIcon {
				color: rgb(255, 255, 255);
			}
		}
/*		.clListIsSelected {
			.clCircle {
				&::before {
					background: rgb(98, 100, 167);
				}
				.clIcon {
					color: rgb(41, 41, 41);
				}
			}
		}*/
	}
	&:global(.contrast) {
		.clListItem{
			&:hover {
				background-color: rgb(255, 255, 0);
				color: rgb(0, 0, 0);
				.clListStatus {
					:global(.ui-text) {
						color: rgb(0, 0, 0);
					}
				}
				.clCircle {
					display: inline-block;
				}
				.clIcon {
					top: 1px;
					&.clListSelectedIcon {
						top: 0px;
					}
				}
			}
		}
		.clCircle {
			&::before {
				background: rgb(0, 0, 0);
			}
			.clIcon {
				color: rgb(255, 255, 255);
				left: 0;
			}
		}
		.clListItem.clListIsSelected {
			background-color: rgb(0, 235, 255);
			color: rgb(0, 0, 0);
			&:hover {
				background-color: rgb(255, 255, 0);
			}
			.clListStatus {
				:global(.ui-text) {
					color: rgb(0, 0, 0);
				}
			}
			.clCircle {
				display: inline-block;
				&::before {
					background: rgb(0, 235, 255);
				}
				.clIcon {
					color: rgb(0, 0, 0);
					top: 0px;
				}
			}
		}
		.clListStatus {
			:global(.ui-text) {
				color: rgb(255, 255, 255);
			}
		}
	}
	.clListInfo {
		padding-top: 6px;
		width: 25px;
	}
	.clListStatus {
		width: 200px;
	}
	.clListDivision {
		width: 200px;
	}
	.clListOrganisationalUnit {
		width: 300px;
	}
	.clListResponsibleZvei {
		width: 300px;
	}
	.clListName {
		width: 500px;
	}
}

.clPanel {
	:global {
		.ms-Panel-commands {
			display: none;
		}
		.ms-Panel-content {
			padding: 0;
		}
	}
	.clPanelContent {
		height: 100vh;
		overflow: scroll;
		padding: 24px;
	}
}

:global(.ms-Panel-navigation) {
	display: none;
}