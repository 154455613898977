.appointmentCardContainer {
    margin-bottom: 25px !important;
    overflow: hidden;
    width: 200px !important;
    .acDateBig {
        border-width: 1px;
        border-style: solid;
        margin: auto;
        padding: 20px;
        width: 125px;
    }

    .acContent {
        margin: 20px 0;
    }
}