.technicalStatusText {
    &.active {
        color: green;
    }
    &.error {
        color: red;
    }
    &.invited {
        color: orange;
    }
}