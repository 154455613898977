.memberListContainer {
    background-color: transparent;
    max-width: 1380px;
    .mlDescription {
        padding-left: 10px;
    }
    .mlArea {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    .mlReviniteButton {
        margin-right: -0.625rem;
    }
    .reinviteButton {
        max-width: 350px;
    }
    .nameCol {
        min-width: 50%;
    }
    .statusCol {
        max-width: 150px;
    }
    .loginCol {
        max-width: 200px;
    }
}